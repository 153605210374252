import {Card} from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import React, {useEffect, useState} from "react";
import Components from "./Components";
import CockpitService from "../../../services/CockpitService";
import {useNavigate} from "react-router-dom";

function Watchlist(handleStockView) {
    const [symbolAlert, setSymbolAlert] = useState(false);
    const [stocks, setStocks] = useState({});
    const [tickInp, setTickInp] = useState("");
    const [searched, setSearched] = useState(false);
    const [searchRes, setSearchRes] = useState([]);
    const [adding, setAdding] = useState(false);
    const [searchAlert, setSearchAlert] = useState(false);

    useEffect(() => {
        CockpitService.getMarketQuotes()
            .then((res) => res.json())
            .then((response) => {
                setStocks(response)
            })
            .catch(err => alert(err.message))
        // refreshPrices()
    }, [])

    // function refreshPrices() {
    //     setInterval(() => {
    //         CockpitService.getMarketQuotes()
    //             .then((res) => res.json())
    //             .then((response) => {
    //                 for (let i = 0; i < response.length; i++) {
    //                     setStocks(response);
    //                 }
    //             })
    //
    //     }, 60000);
    // }

    async function handleSubmit() {
        setAdding(true)
        CockpitService.handleSearchSubmit(tickInp)
            .then((res) => res.json())
            .then((response) => {
                console.log(response);
                if (response.length === 0) {
                    setSymbolAlert(true);
                    setAdding(false);
                } else if (!"details" in response) {
                    setStocks(response);
                    handleStockView(response[response.length - 1])
                }
                setAdding(false);
            })
            .catch(err => {
                alert(err.message);
            })
    }

    const handleAddPos = async (e) => {
        setAdding(true);
    }

    const handleAddClose = async (e) => {
        setAdding(false);
    }

    const handleTickChange = async (e) => {
        setTickInp(e.target.value);
        await search(e.target.value).catch(err => alert(err.message));
    }

    async function clickTickRec(props) {
        setTickInp(props);

        setSearched(false);
    }

    async function search(props) {
        if (props.length >= 2) {
            setSearched(true);
            CockpitService.search(props)
                .then((res) => res.json())
                .then((response) => {
                    setSearchRes(response)
                })
                .catch(err => {
                    alert(err.message)
                })
        } else {
            setSearched(false);
        }
    }

    async function handleBlur() {
        setSearched(false);
    }

    return (<div style={{}}>
        {/*<Card style={{height: '0.3em', backgroundColor: '#353535'}}></Card>*/}
        {symbolAlert && Components.tickerErrorAlert(setSymbolAlert)}
        {Components.tickerSearch(tickInp, handleTickChange, handleSubmit, handleBlur)}
        {searched && Components.searchSuggestions(searchRes, clickTickRec)}
        {/*<Card style={{height: '0.3em', marginBlock: "0.5em", backgroundColor: '#353535'}}></Card>*/}
        {ListStocks(stocks, adding, handleStockView)}
    </div>)

}

export default Watchlist;

function ListStocks(stocks, adding, handleStockView) {
    return (
        <div className="List" style={{overflowY: "auto", marginTop: '0.2em', maxHeight: "70vh", marginBottom: '0.2em'}}>
            <Table bsPrefix="custom-table">
                <thead>
                <tr>
                    <th>Symbol</th>
                    <th>Price</th>
                    <th>Change</th>
                    <th>Change (%)</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(stocks).map(([index, stock]) => (<tr className="tr-wl" id={index} onClick={() => {
                    Array.from(document.getElementsByClassName("tr-wl")).forEach(el => {
                        el.style = "background-color: none";
                    })
                    document.getElementById(index).style = "background-color: rgb(80, 80, 80)";
                    handleStockView(stock)
                }} key={index}>
                    <td>{stock["symbol"]}</td>
                    <td>{Number(stock["price"]["05. price"]).toFixed(2)}</td>
                    {(Number(stock["price"]["09. change"]) >= 0) ? <td style={{
                        color: "#65D190", margin: "0"
                    }}>{Number(stock["price"]["09. change"]).toFixed(2)}</td> : <td style={{
                        color: "#D47777", margin: "0"
                    }}>({Number(stock["price"]["09. change"]).toFixed(2) * -1})</td>}
                    {(Number(stock["price"]["09. change"]) >= 0) ?
                        <td style={{color: "#65D190", margin: "0"}}>{Number(String(stock["price"]["10. change percent"])
                            .substring(0, String(stock["price"]["10. change percent"]).length - 1)).toFixed(2)}%
                        </td> : <td style={{
                            color: "#D47777", margin: "0"
                        }}>({Number(String(stock["price"]["10. change percent"])
                            .substring(1, String(stock["price"]["10. change percent"]).length - 1)).toFixed(2)})%</td>}
                </tr>))}
                </tbody>
                {adding && <tfoot>Loading</tfoot>}
            </Table>
        </div>)
}

