import {BACKEND_API} from "../../API_URLS";
import Cookies from "universal-cookie";

const API_URL = BACKEND_API + "cockpit/";
const cookies = new Cookies();

const CockpitService = {
    async getStocks() {
        return await fetch(API_URL + "get-stocks", {
            method: 'GET', headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async getPrices() {
        return await fetch(API_URL + "get-prices", {
            method: 'GET', headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async getMarketQuotes() {
        return await fetch(API_URL + "get-market-quotes", {
            method: 'GET', headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async handleSearchSubmit(tickInp) {
        return await fetch(API_URL + "handle-add-stock-watchlist/" + tickInp, {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async handleRemoveStock(tickInp) {
        return await fetch(API_URL + "remove-stock-from-watchlist/" + tickInp, {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async search(props) {
        return await fetch(API_URL + "ticker-search/" + props, {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    }
}

export default CockpitService;
