const PositionsResource = {
    calculateAll(poss, marketPrice) {
        if (poss.length === 0) {
            return {
                "profit": 0,
                "initialInvested": 0,
            }
        }

        return {
            "profit": this.profit(poss, marketPrice),
            "initialInvested": this.initialInvested(poss),
        }
    },

    profit(poss, marketPrice) {
        let total = 0;
        let i = 0;

        for (i = 0; i < poss.length; i++) {
            if (poss[i]["buy"] === true) {
                total += (Number(marketPrice) - Number(poss[i]["price"])) * Number(poss[i]["quant"])
            } else {
                total += (Number(poss[i]["price"]) - Number(marketPrice)) * Number(poss[i]["quant"])
            }
        }

        return total;
    },

    initialInvested(poss) {
        let total = 0;
        let i;
        for (i = 0; i < poss.length; i++) {
            if (poss[i]["buy"] === true) {
                total += Number(poss[i]["price"])*Number(poss[i]["quant"])
            } else {
                total -= Number(poss[i]["price"])*Number(poss[i]["quant"])
            }
        }

        return total;
    }
}

export default PositionsResource;
