import {BrowserRouter, Route, Routes} from "react-router-dom";
import CockPit from "./pages/cockpit/CockPit";
import Access from "./pages/Access";
import Graph from "./pages/graph/Graph";
import AdminControl from "./admin/AdminControl"
import React, {useEffect} from "react";

export default function App() {
    useEffect(() => {
        document.title = "BullBull - CockPit"
    }, [])

    return (
        <BrowserRouter>
            <Routes path="/">
                <Route path="/" element={<Access/>}/>
                <Route path="/cockpit" element={<CockPit/>}/>
                {/*<Route path="/graph" element={<Graph/>}/>*/}
                <Route path="/admin-control" element={<AdminControl/>}/>
                {/*<Route path="/f-p-graph" element={<FullPageGraph/>} />*/}
            </Routes>
        </BrowserRouter>
    )
}
