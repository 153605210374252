// TradingViewWidget.jsx
import React, {useEffect, useRef, memo, useState} from 'react';
import {Container} from "react-bootstrap";

function TradingViewWidget(props) {
    const container = useRef();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
          "symbol": "${props["currStock"]}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com",
          "height": 520,
          "width":"100%"
        }`;
        container.current.innerHTML = "";
        container.current.appendChild(script);
    }, [props]);

    return (<div className="tradingview-widget-container" ref={container} style={{height: "100%", width: "100%"}}>
        <div className="tradingview-widget-container__widget"
             style={{height: "calc(100% - 32px)", width: "100%"}}></div>
        <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow"
                                                         target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
        </div>
    </div>);
}

export default memo(TradingViewWidget);
