import {Alert, Button, Dropdown, Form, FormControl, InputGroup} from "react-bootstrap";
import React from "react";
import '../../../../static/CockPit.css';

class Components {
    static searchSuggestions(searchRes, clickTickRec) {
        return <Dropdown.Menu show style={{
            marginLeft: '0.5em', maxWidth: '35%', borderColor: 'grey', backgroundColor: 'grey', borderRadius: '0.2em',
        }}>
            {searchRes.map((search, index) => (<Dropdown.Item onClick={() => clickTickRec(searchRes[index])}
                                                              key={index}>{searchRes[index]}</Dropdown.Item>))}
        </Dropdown.Menu>;
    }

    static tickerSearch(tickInp, handleTickChange, handleSubmit, handleBlur) {
        return <InputGroup style={{
            borderRadius: '0.5em', marginTop: "0.5em", height: "2em"
        }}>
            <input style={{width: "80%", borderTopLeftRadius: "0.3em", borderBottomLeftRadius: "0.3em", padding: "0.3em", border: "0"}} type="text" name="ticker" value={tickInp}
                          onChange={handleTickChange} onBlur={handleBlur}/>
            <Button variant="outline-secondary" bsPrefix="search-button" onClick={handleSubmit}>Add</Button>
        </InputGroup>;
    }

    static tickerErrorAlert(setSymbolAlert) {
        return <Alert variant="danger" style={{margin: '0.4em', paddingBottom: "0.1em"}} onClose={() => setSymbolAlert(false)}
                      dismissible>
            <p>
                No data found for this ticker.
            </p>
        </Alert>;
    }
}

export default Components;
