import {useEffect, useState} from "react";
import PortfolioDataService from "../../../services/PortfolioDataService";
import {Doughnut, Line} from 'react-chartjs-2'
import {
    ArcElement, Chart as ChartJS, Tooltip, Legend, CategoryScale, LineController, registerables, Colors
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LineController, Colors, ...registerables);
ChartJS.defaults.color = "white"
ChartJS.defaults.font.size = 18

function PortfolioData() {
    const [totalROI, setTotalROI] = useState({});
    const [totalInvested, setTotalInvested] = useState({});
    const [distribution, setDistribution] = useState({});
    const [liveProfit, setLiveProfit]  = useState({});
    useEffect(() => {
        PortfolioDataService.getPortfolioData()
            .then(res => res.json())
            .then((data) => {
                setTotalROI(data["TotalROI"]);
                setTotalInvested(data["TotalInvested"]);
                setDistribution(data["Distribution"]);
                setLiveProfit(data["LiveProfit"]);
                if (data["TotalROI"] === undefined) {
                    setTotalROI({})
                }
                if (data["Distribution"] === undefined) {
                    setDistribution({})
                }
                if (data["TotalInvested"] === undefined) {
                    setTotalInvested({})
                }
                if (data["LiveProfit"] === undefined) {
                    setLiveProfit({})
                }

            });
    }, [])

    return (<div>
        <div className="Info" style={{display: "flex", flexDirection: "column"}}>
            <h3 style={{alignSelf: "center"}}>Total ROI (%)</h3>
            <div>
                <TotalInvestedChart totalInvested={totalROI}/>
            </div>
        </div>
        <div className="Info" style={{display: "flex", flexDirection: "row", overflowX: "scroll"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <h3 style={{alignSelf: "center"}}>Asset Distribution</h3>
                <div>
                    <DistributionChart distribution={distribution}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
                <h3 style={{alignSelf: "center"}}>Live Profit ($)</h3>
                <div>
                    <TotalInvestedChart totalInvested={liveProfit}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
                <h3 style={{alignSelf: "center"}}>Total Invested ($)</h3>
                <div>
                    <TotalInvestedChart totalInvested={totalInvested}/>
                </div>
            </div>
        </div>
    </div>);
}

function DistributionChart(props) {
    return (<Doughnut
        height="400px"
        width="400px"

        options={{
            maintainAspectRatio: false, plugins: {
                colors: {
                    enabled: true, forceOverride: true
                }
            }
        }}
        data={{
            datasets: [{
                data: Object.values(props["distribution"])
            },], labels: Object.keys(props["distribution"]),
        }}

    />);

}

function TotalInvestedChart(props) {
    return (<Line
        height="400px"
        width="400px"

        options={{
            maintainAspectRatio: false, plugins: {
                colors: {
                    enabled: true, forceOverride: true
                }, legend: {
                    display: false
                }
            }, scales: {
                xAxis: {
                    ticks: {
                        maxTicksLimit: 3,
                    }
                }, x: {
                    ticks: {
                        display: false
                    }
                }
            },
        }}
        data={{
            datasets: [{
                data: Object.values(props["totalInvested"]),
            },], labels: Object.keys(props["totalInvested"]).map((str) => {
                return new Date(str).toLocaleDateString('us-en', {
                    day: '2-digit', month: '2-digit', year: '2-digit',
                })

            }),
        }}

    />);

}

export default PortfolioData;
