import React, {useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/Access.css';
import {BACKEND_API} from "../API_URLS";
import Cookies from 'universal-cookie';
import AccessService from "./services/AccessService";
import logo from "../images/bull_icon_white.png";

const cookies = new Cookies();


const API_URL = BACKEND_API + "access/";

function Access() {
    const [logAlert, setLogAlert] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordDiff, setPasswordDiff] = useState(false);
    const [email, setEmail] = useState("");
    const [signup, setSignup] = useState(false);

    useEffect(() => {
        AccessService.testLogged()
            .then(res => res.json())
            .then(response => {
                if (response["status"] === "true") {
                    navigate('/cockpit')
                }
            }).catch((err) => console.log("Error"))
    }, [])

    const handleUsername = (e) => {
        setUsername(e.target.value);
    }


    const handlePassword = (e) => {
        setPassword(e.target.value);
    }


    const handlePassword2 = (e) => {
        setPassword2(e.target.value);
        if (password !== e.target.value) {
            setPasswordDiff(true);
        } else {
            setPasswordDiff(false);
        }
    }


    const handleEmail = (e) => {
        setEmail(e.target.value);
    }


    const activateSignupForm = (e) => {
        setSignup(true);
    }

    const activateLoginForm = (e) => {
        setSignup(false);
    }

    const navigate = useNavigate();

    async function handleSignup() {
        if (passwordDiff) {
            return
        }

        let status;
        let credentials = {username: username, password: password, email: email}

        await AccessService.signup(credentials)
            .then((res) => res.json())
            .then((response) => {
                status = response["status"];
                if (response["status"] === "OK") {
                    cookies.set('token', response["token"]);
                }
            })
            .catch(err => {
                alert(err.message);
            });

        if (status === "OK") {
            navigate('/cockpit')
        } else {
            setLogAlert(true);
        }

    }

    async function handleLogin() {
        let credentials = {
            username: username, password: password
        }
        let status;

        await AccessService.login(credentials)
            .then((res) => res.json())
            .then((response) => {
                status = response["status"];
                if (response["status"] === "OK") {
                    cookies.set('token', response["token"]);
                }
            })
            .catch(err => {
                alert(err.message);
            });

        if (status === "OK") {
            navigate('/cockpit')
        } else {
            setLogAlert(true);
        }
    }


    return (<div className="Access">
        <br/>
        {(logAlert && !signup) &&
            <Alert variant="danger" style={{margin: '0.4em', maxWidth: "40em", alignSelf: "center"}}
                   onClose={() => setLogAlert(false)}
                   dismissible>
                <Alert.Heading>Wrong username or password.</Alert.Heading>
            </Alert>}

        {(logAlert && signup) &&
            <Alert variant="danger" style={{margin: '0.4em', maxWidth: "40em", alignSelf: "center"}}
                   onClose={() => setLogAlert(false)}
                   dismissible>
                <Alert.Heading>Wrong username, password or email.</Alert.Heading>
                <p>Username and Password must contain 8 characters (alphabetical, numerical or special; no ','). Email
                    must
                    be a real email.</p>
            </Alert>}

        {(passwordDiff) && <Alert variant="danger" style={{margin: '0.4em', maxWidth: "40em", alignSelf: "center"}}
                                  onClose={() => setLogAlert(false)}
        >
            <Alert.Heading>Passwords are not matching.</Alert.Heading>
        </Alert>}

        <div style={{alignSelf: 'center', display: 'flex', flexDirection: 'row', paddingTop: '1em', paddingBottom: '0.5em'}}>
            <img src={logo} height='80' width='80' style={{marginRight: "-0.8em"}}/>
            <h2 style={{marginTop: "0.4em", fontFamily: "Courier New", fontSize: "2.6em"}}>|BullBull</h2>
        </div>
        <div className="Info" style={{width: "20em", padding: "2.5em", marginInline: 0, alignSelf: "center"}}>
            {signup ? <Form className="Form" style={{display: "flex", flexDirection: "column"}}>
                <h2>Sign Up</h2>
                <label className="access-label">Username</label>
                <input className="access-input" type="text" name="username" value={username}
                       onChange={handleUsername}></input>
                <label className="access-label">Password</label>
                <input className="access-input" type="password" name="password" value={password}
                       onChange={handlePassword}></input>
                <label className="access-label">
                    Confirm Password
                </label>
                <input className="access-input" type="password" name="password2" value={password2}
                       onChange={handlePassword2}></input>
                <label className="access-label">
                    Email
                </label>
                <input className="access-input" type="email" name="email" value={email} onChange={handleEmail}></input>
                <Button bsPrefix="butt" onClick={handleSignup}
                        style={{margin: "1em", width: "8em", height: "4em", alignSelf: "center", color: "black"}}>Sign Up</Button>
                <Button bsPrefix="butt" onClick={activateLoginForm}
                        style={{margin: "1em", width: "6em", height: "4em", alignSelf: "center"}}>Log In Instead</Button>
            </Form> : <Form className="Form" style={{display: "flex", flexDirection: "column"}}>
                <h2>Log In</h2>
                <label className="access-label">
                    Username
                </label>
                <input className="access-input" type="text" name="username" value={username}
                       onChange={handleUsername}></input>
                <label className="access-label">
                    Password
                </label>
                <input className="access-input" type="password" name="password" value={password}
                       onChange={handlePassword}></input>
                <Button bsPrefix="butt" onClick={handleLogin}
                        style={{margin: "1em", width: "8em", height: "4em", alignSelf: "center", color: "black"}}>
                    Log In
                </Button>
                <Button bsPrefix="butt" onClick={activateSignupForm}
                        style={{margin: "1em", width: "6em", height: "4em", alignSelf: "center"}}>
                    Create Account</Button>
            </Form>

            }
        </div>
    </div>)
}


export default Access;
