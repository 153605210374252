import {BACKEND_API} from "../../API_URLS";
import Cookies from "universal-cookie";


const cookies = new Cookies();
const API_URL = BACKEND_API + "cockpit/";

const StockPosService = {

    async getPos(currStock) {
        return await fetch(API_URL + "get-all-pos-by-user/", {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            }),
            body: JSON.stringify(
                {"currStock": currStock}
            )
        })
    },

    async saveEdit(posId, quant, date, price, buy) {
        await fetch(API_URL + "edit-pos/", {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                id: posId,
                quant: quant,
                date: date,
                price: price,
                buy: buy
            })
        })
    },

    async delPos(id) {
        return await fetch(API_URL + "del-stock-pos/" + id, {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async createPos(symbol, quant, date, price, buy) {
        return await fetch(API_URL + "create-pos/", {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                symbol: symbol,
                quant: quant,
                date: date,
                price: price,
                buy: buy
            })
        })
    }
}

export default StockPosService;
