import Cookies from "universal-cookie";
import {BACKEND_API} from "../../API_URLS";

const cookies = new Cookies();

const API_URL = BACKEND_API + "cockpit/";

const PortfolioDataService = {
    getPortfolioData() {
        return fetch(API_URL + "get-portfolio", {
            method: "POST", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        });
    }
}

export default PortfolioDataService;
