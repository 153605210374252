import '../../static/CockPit.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Card, Col, Container, Row} from "react-bootstrap";

import Watchlist from "./components/Watchlist/Watchlist";
import Graph from "../graph/Graph";
import Positions from "./components/Positions/Positions";

import Cookies from 'universal-cookie';

import AccessService from "../services/AccessService";
import CockpitService from "../services/CockpitService";
import logo from '../../images/bull_icon_white.png'
import LightWeightGraph from "../TradingViewGraphs/LightWeightGraph";
import PortfolioData from "./components/PortfolioData/PortfolioData";

const cookies = new Cookies();

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {width, height};
}

function CockPit() {
    const [reload, setReload] = useState(false);
    const [currStock, setCurrStock] = useState("Home");
    const [selling, setSelling] = useState(false)
    const [marketPrice, setMarketPrice] = useState(0);
    const [collapseWatchlist, setCollapseWatchList] = useState(false);
    const [fullWatchlist, setFullWatchlist] = useState(true);

    useEffect(() => {
        checkSize()

        AccessService.testLogged()
            .then(res => res.json())
            .then(response => {
                if (response["status"] !== "true") {
                    navigate('/')
                }
            })
            .catch(err => navigate('/'))

        window.addEventListener('resize', checkSize);
    }, [window])

    function checkSize() {
        if (getWindowDimensions().width < 1000) {
            setCollapseWatchList(true);
        } else {
            setCollapseWatchList(false);
            document.getElementById("sidebar").style.setProperty("max-width", "100%");
            document.getElementById("sidebar").style.setProperty("visibility", "visible");
            document.getElementById("sidebar").style.setProperty("margin-right", "1.8em");
        }
    }

    function toggleWatchlist() {
        if (!collapseWatchlist) {
            return
        }


        if (fullWatchlist) {
            setFullWatchlist(false)
            document.getElementById("sidebar").style.setProperty("max-width", "0");
            document.getElementById("sidebar").style.setProperty("visibility", "hidden");
            document.getElementById("sidebar").style.setProperty("margin-right", "-1.5em");
        } else {
            setFullWatchlist(true)
            document.getElementById("sidebar").style.setProperty("max-width", "100%");
            document.getElementById("sidebar").style.setProperty("visibility", "visible");
            document.getElementById("sidebar").style.setProperty("margin-right", "1.8em");
        }
    }

    async function handleSetSell() {
        setSelling(true);
    }

    async function handleQuitSell() {
        setSelling(false);
    }

    function handleStockView(stock) {
        setCurrStock(stock["symbol"]);
        setMarketPrice(stock["price"]);
        setSelling(false);
        toggleWatchlist();
    }

    async function handleRemoveStock() {
        await CockpitService.handleRemoveStock(currStock).catch(err => alert(err.message));

        window.location.reload();
    }

    const navigate = useNavigate();
    const fullPageGraph = (e) => {
        navigate("/graph", {state: currStock})
    }

    function handleHome() {
        Array.from(document.getElementsByClassName("tr-wl")).forEach(el => {
            el.style = "background-color: none";
        })
        setCurrStock("Home");
    }

    async function handleLogOut() {
        await AccessService.logout().catch(err => alert(err.message));
        cookies.set("token", "");
        navigate("/")
    }

    return (<div className="CockPit">
        <Container fluid className="no-scroll">
            <Row style={{height: '100vh'}}>
                {collapseWatchlist ? <Col id="sidebar" xs={12}
                                          style={{
                                              height: '100%',
                                              width: '100%',
                                              display: "flex",
                                              flexDirection: "column",
                                              textAlign: 'center',
                                          }}>
                    <div style={{
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: '1em',
                        paddingBottom: '0.5em'
                    }}>
                        <img src={logo} height='80' width='80' style={{marginRight: "-0.8em"}}/>
                        <h2 style={{marginTop: "0.4em", fontFamily: "Courier New", fontSize: "2.6em"}}
                            onClick={handleHome}>|BullBull</h2>
                    </div>
                    <div style={{maxHeight: "90vh"}}>
                        {Watchlist(handleStockView)}
                    </div>
                    <div style={{margin: "0.5 em"}}>
                        <Button bsPrefix="butt" onClick={handleLogOut}>Log Out</Button>
                        <Button bsPrefix="butt" onClick={() => {
                            setCurrStock("Home")
                            toggleWatchlist()
                        }} style={{marginInline: "0.5em"}}>Portfolio</Button>
                    </div>
                </Col> : <Col id="sidebar" xs={4}
                              style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: 'center',
                                  borderColor: "#000000",
                              }}>
                    <div style={{
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: '1em',
                        paddingBottom: '0.5em'
                    }}>
                        <img src={logo} height='80' width='80' style={{marginRight: "-0.8em"}}/>
                        <h2 style={{marginTop: "0.4em", fontFamily: "Courier New", fontSize: "2.6em"}}
                            onClick={handleHome}>|BullBull</h2>
                    </div>
                    <div style={{height: "80%"}}>
                        {Watchlist(handleStockView)}
                    </div>
                    <Button bsPrefix="butt" style={{float: "left", margin: "1em"}} onClick={handleLogOut}>Log
                        Out</Button>
                </Col>}
                <Col style={{overflowY: "scroll", height: "100%"}}>
                    {currStock !== "Home" ? <div>
                        {collapseWatchlist && <Button bsPrefix="butt" style={{
                            height: "3.2em", width: "10em", marginTop: "1em"
                        }} onClick={toggleWatchlist}>{"<"} Back to Watchlist</Button>}
                        <div className="Info " style={{overflowX: "auto"}}>
                            <div>
                                <label className="largeLabel">Ticker</label>
                                <h1 className="InfoLabel">{currStock}</h1>
                            </div>
                            <div style={{marginLeft: "4em"}}>
                                <label className="largeLabel">Market Price</label>
                                <h5 className="InfoLabel">{Number(marketPrice["05. price"]).toFixed(2)}</h5>
                            </div>
                        </div>
                        <Positions props={[currStock, marketPrice]}></Positions>
                        <div className="Info" style={{flexDirection: "column", padding: 0}}>
                            {/*<div style={{textAlign: 'right'}}>*/}
                            {/*    <Button bsPrefix="butt" style={{height: "3.5em"}} onClick={fullPageGraph}>*/}
                            {/*        Full Page*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                            <div style={{textAlign: 'left'}}>
                                    <LightWeightGraph currStock={currStock}/>
                            </div>
                        </div>
                        <Button bsPrefix="butt" style={{width: "10em", height: "4em"}}
                                onClick={handleRemoveStock}>Remove from watchlist.</Button>
                    </div> : <div>
                        {collapseWatchlist && <Button bsPrefix="butt" style={{
                            height: "3.2em", width: "10em", marginLeft: "1em", marginTop: "1em"
                        }} onClick={toggleWatchlist}>{"<"} Back to Watchlist</Button>}
                        <PortfolioData/>
                    </div>}
                </Col>
            </Row>
        </Container>
    </div>)
}

export default CockPit;
