import React, {useEffect, useState} from "react";
import StockPosService from "../../../services/StockPosService";
import {Alert, Button, InputGroup} from "react-bootstrap";
import PositionsResource from "./PositionsResource";

function Positions(props) {
    const [currStock, setCurrStock] = useState("");
    const [marketPrice, setMarketPrice] = useState(0);

    const [poss, setPoss] = useState([]);
    const [editing, setEditing] = useState(-1);
    const [dateAlert, setDateAlert] = useState(false);
    const [datePos, setDatePos] = useState([]); // TODO: change to multiple
    const [hidePositions, setHidePositions] = useState(false);

    const [aggregatePositionInfo, setAggregatePositionInfo] = useState({});

    useEffect(() => {
        let stock = props["props"][0];
        let price = props["props"][1]["05. price"];

        setCurrStock(stock)
        setMarketPrice(price)

        StockPosService.getPos(stock)
            .then(res => res.json())
            .then(response => {
                setPoss(response)
                setDatePos(parseDates(response))
                setAggregatePositionInfo(PositionsResource.calculateAll(response, price))
            })
            .catch(err => alert(err.message))
    }, [props["props"][0]])

    function parseDates(response) {
        let arr = []
        let d
        for (let i = 0; i < response.length; i++) {
            d = new Date(response[i]["date"]);
            arr.push(d.toLocaleDateString());
        }

        return arr;
    }

    function toggleHidePositions() {
        if (hidePositions) {
            setHidePositions(false)
        } else {
            setHidePositions(true)
        }
    }

    async function handleEditPos(index) {
        setEditing(index);
    }

    async function handleBackEdit() {
        setEditing(-1);
    }

    async function handleDeletePos(index) {
        if (poss[index]["id"] == null) {
            poss.pop();
        } else {
            StockPosService.delPos(poss[index]["id"])
                .then((res) => res.json())
                .then(response => {
                    setPoss(response)
                    setDatePos(parseDates(response))
                    setAggregatePositionInfo(PositionsResource.calculateAll(response,  marketPrice))
                })
        }

        setEditing(-1);

    }

    async function handleSaveEdit(index) {

        let price = Number(document.getElementById("price_inp_" + index).value);
        let quant = Number(document.getElementById("quant_inp_" + index).value);
        let date = new Date(document.getElementById("date_inp_" + index).value);
        let buy = String(document.getElementById("buy_inp_" + index).value) === "true";

        poss[index]["price"] = price
        poss[index]["quant"] = quant
        poss[index]["date"] = date.setDate(date.getDate() + 1);
        datePos[index] = date.toLocaleDateString();
        poss[index]["buy"] = buy;

        if (poss[index]["id"] == null) {
            StockPosService.createPos(currStock, quant, Date.parse(date), price, buy)
                .then(res => res.json())
                .then(response => {
                    poss[index]["id"] = response
                })
        } else {
            await StockPosService.saveEdit(poss[index]["id"], quant, Date.parse(date), price, buy)
        }

        setAggregatePositionInfo(PositionsResource.calculateAll(poss, marketPrice))

        setEditing(-1);
    }

    async function handleCreatePos() {
        poss.push({
            "id": null, "symbol": currStock, "price": 0, "quantity": 0, "date": new Date(), "buy": true,
        })

        setEditing(poss.length - 1)
    }

    return (<div>
        <div className="Info" style={{
            width: "100%",
            height: "3em",
            marginBottom: "0.1em",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "",
            padding: 0,
            paddingTop: "0.7em",
            paddingBottom: "1em",
            color: "white",
            fontWeight: 300,
        }}>
            Order History
        </div>
        {hidePositions ? <div>
            {Object.entries(poss).map(([index, pos]) => (<div className="Info" style={{
                overflowX: "auto", marginBlock: "-0.1em", borderRadius: 0, marginInline: "4%", width: "92%"
            }} key={index}>
                <div className="SideInfo">
                    <label className="largeLabel">Order Price</label>
                    <div>
                        {editing == index ?
                            <input id={"price_inp_" + index} className="InfoInput" type="number" name="price"
                                   defaultValue={pos["price"]}/> :
                            <p className="InfoLabel" style={{width: "6em"}}>{pos["price"].toFixed(2)}</p>}
                    </div>
                </div>
                <div className="SideInfo">
                    <label className="SmallLabel">Quantity</label>
                    <div>
                        {editing == index ?
                            <input id={"quant_inp_" + index} className="InfoInput" type="number" name="quant"
                                   defaultValue={pos["quant"]}/> : <p className="InfoLabel">{pos["quant"]}</p>}
                    </div>
                </div>
                <div className="SideInfo">
                    <label className="SmallLabel">Date</label>
                    <div>
                        {editing == index ?
                            <input id={"date_inp_" + index} className="InfoInput" type="date" name="date"
                                   defaultValue={new Date().toISOString().substring(0, 10)}/> :
                            <p className="InfoLabel">{datePos[index]}</p>}
                    </div>
                </div>
                <div className="SideInfo">
                    <label className="SmallLabel">Buy</label>
                    <div>
                        {editing == index ? <div>
                            <select id={"buy_inp_" + index} className="InfoInput" type="text" name="buy"
                                    defaultValue={pos["buy"].toString()}>
                                <option value="true">Purchase</option>
                                <option value="false">Sell</option>
                            </select>
                        </div> : <div>
                            {pos["buy"] === true ? <p className="InfoLabel">Purchase</p> :
                                <p className="InfoLabel">Sell</p>}
                        </div>}
                    </div>
                </div>
                <div style={{flexDirection: 'column'}}>
                    {editing != index ? <div>
                        <Button bsPrefix="butt"
                                style={{alignSelf: 'right', width: "6em", height: "4em", marginInline: "6em"}}
                                onClick={() => handleEditPos(index)}>
                            Edit Position
                        </Button>
                    </div> : <div>
                        <Button bsPrefix="butt" style={{alignSelf: 'right', margin: "0.25em"}}
                                onClick={() => handleDeletePos(index)}>
                            Delete
                        </Button>
                        {dateAlert && <Alert variant="danger" style={{margin: '0.4em'}}
                                             onClose={() => setDateAlert(false)}
                                             dismissible>
                            <Alert.Heading>Date is invalid.</Alert.Heading>
                            <p>
                                Date set to most recent.
                            </p>
                        </Alert>}
                        <Button bsPrefix="butt" style={{alignSelf: 'right', margin: "0.25em"}}
                                onClick={handleBackEdit}>
                            Back
                        </Button>
                        <Button bsPrefix="butt" style={{alignSelf: 'right', margin: "0.25em"}}
                                onClick={() => handleSaveEdit(index)}>
                            Save
                        </Button>
                    </div>}
                </div>
            </div>))}
            <div style={{display: "flex", flexDirection: "row"}}>
                <div className="DetailsCardButton"
                     onClick={toggleHidePositions} style={{marginBlock: "-0.1em", marginLeft: "4%", width: "46%"}}>
                    ^
                </div>
                <div className="DetailsCardButton"
                     onClick={handleCreatePos} style={{marginBlock: "-0.1em", marginRight: "4%", width: "46%", fontWeight: 100}}>
                    Add Order
                </div>
            </div>
        </div> : <div className="DetailsCardButtonDrop" style={{marginBlock: "-0.1em", marginInline: "20%", width: "60%"}}
                      onClick={toggleHidePositions}>
            &#8964;
        </div>}
        <div className="Info" style={{overflowX: "auto"}}>
            <div className="SideInfo">
                <label className="largeLabel">Total Invested</label>
                <div>
                    {Number(aggregatePositionInfo["initialInvested"] + aggregatePositionInfo["profit"]) >= 0 ?
                        <p className="InfoLabel" style={{
                            color: "#65D190"
                        }}>${(aggregatePositionInfo["initialInvested"] + aggregatePositionInfo["profit"]).toFixed(2)}</p> :
                        <p className="InfoLabel" style={{
                            color: "#D47777"
                        }}>$({((aggregatePositionInfo["initialInvested"] + aggregatePositionInfo["profit"]) * -1).toFixed(2)})</p>}
                </div>
            </div>
            <div className="SideInfo" style={{marginInline: "3em"}}>
                <label className="largeLabel">Live Profit</label>
                <div>
                    {Number(aggregatePositionInfo["profit"]) >= 0 ? <p className="InfoLabel" style={{
                        color: "#65D190"
                    }}>${Number(aggregatePositionInfo["profit"]).toFixed(2)}</p> : <p className="InfoLabel" style={{
                        color: "#D47777"
                    }}>$({(Number(aggregatePositionInfo["profit"]) * -1).toFixed(2)})</p>}
                </div>
            </div>
            {/*TODO: DIVIDEND YIELD*/}
        </div>
    </div>)
}


export default Positions;
