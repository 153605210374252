import {BACKEND_API} from "../API_URLS";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const AdminService = {
    testLogged() {
        return fetch(BACKEND_API + "auth-utils/test-admin-credentials", {
            method: 'GET', headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    updateStocks() {
        return fetch(BACKEND_API + "admin/force-update-stocks", {
            method: 'GET', headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    }
}

export default AdminService;
