import {BACKEND_API} from "../../API_URLS";
import Cookies from "universal-cookie";

const API_URL = BACKEND_API + "access/";
const cookies = new Cookies();

const AccessService = {
    async testLogged() {
        return fetch(API_URL + "test-logged", {
            method: "GET", headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    async logout() {
        await fetch(API_URL + "logout", {
            method: 'GET', headers: new Headers({
                'Authorization': 'Bearer ' + cookies.get('token'), 'Content-Type': 'application/json',
            })
        })
    },

    signup(credentials) {
        return fetch(API_URL + "signup", {
            method: "POST", headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify(credentials)
        })
    },

    login(credentials) {
        return fetch(API_URL + "login", {
            method: "POST", headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify(credentials),
        })
    }
}

export default AccessService;
