import {useEffect, useState} from "react";
import AdminService from "./AdminService";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

function AdminControl() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [updateStocksMessage, setUpdateStocksMessage] = useState("");

    useEffect(() => {
        AdminService.testLogged()
            .then(res => res.json())
            .then(response => {
                if (response !== true) {
                    navigate("/cockpit")
                } else {
                    setShow(true)
                }
            })
            .catch(err => alert(err.message))
    }, [])

    function updateStocks() {
        AdminService.updateStocks()
            .then(res => res.json())
            .then(response => {
                setUpdateStocksMessage(response["status"])
            })
            .catch(err => alert(err.message))
    }

    return (<div>
            {show && <div>
                <button onClick={updateStocks}>UpdateStocks</button>
                <p>{updateStocksMessage}</p>
            </div>}
        </div>)

}

export default AdminControl;
